import React, {useState, useRef, useEffect} from "../../../_snowpack/pkg/react.js";
import {Switch, Route, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import {SearchContext} from "../../contexts/search-context.js";
import debounce from "../../utils/debounce.js";
import DashboardSearch from "../dashboard/dashboard-search.js";
import ScanResults from "../scan-results/index.js";
import ProductDetail from "../product-detail/index.js";
import BuyLists from "../buy-lists/index.js";
import BuyList from "../buy-list/index.js";
const Content = ({isDashboardUser, counter}) => {
  const [searchContext, setSearchContext] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [lastResultsPageY, setLastResultsPageY] = useState(0);
  const [results, setResults] = useState([]);
  const location = useLocation();
  const mainContentRef = useRef(null);
  const isResultMounted = useRef(false);
  const mainContentScrollHandler = (e) => {
    if (isResultMounted.current && location.pathname.startsWith("/results")) {
      setLastResultsPageY(e.target.scrollTop);
    }
  };
  const debouncedMainContentScrollHandler = debounce(mainContentScrollHandler, 50);
  useEffect(() => {
    if (mainContentRef.current && location.pathname.startsWith("/results")) {
      mainContentRef.current.scrollTop = lastResultsPageY;
    }
  }, [location]);
  return /* @__PURE__ */ React.createElement("main", {
    style: {gridArea: "main", overflow: "auto"},
    ref: mainContentRef,
    onScroll: debouncedMainContentScrollHandler
  }, /* @__PURE__ */ React.createElement(SearchContext.Provider, {
    value: {
      pageIndex,
      setPageIndex,
      searchContext,
      setSearchContext,
      results,
      setResults,
      isResultMounted,
      setHasNextPage,
      hasNextPage
    }
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/dashboard",
    component: DashboardSearch
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/results/:barcode",
    component: ScanResults
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/products/:barcode",
    render: (props) => /* @__PURE__ */ React.createElement(ProductDetail, {
      ...props,
      counter
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: ["/buy-lists/:buyListId/:buyListItemId", "/buy-lists/:buyListId"],
    render: (p) => /* @__PURE__ */ React.createElement(BuyList, {
      ...p,
      isDashboardUser
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/buy-lists",
    component: BuyLists
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/archive-buy-lists",
    component: BuyLists
  }))));
};
export default Content;
