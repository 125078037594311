import React, {useRef, useState, useEffect, Fragment} from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router.js";
import {Dialog, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import SubtractFillIcon from "../../../_snowpack/pkg/remixicon-react/SubtractFillIcon.js";
import AddFillIcon from "../../../_snowpack/pkg/remixicon-react/AddFillIcon.js";
import CurrencyInput from "../../../_snowpack/pkg/react-currency-input-field.js";
import axios from "../../../_snowpack/pkg/axios.js";
import TaxCodeInput from "../tax-code-input/index.js";
import CreateBuyListButton from "../create-buy-list-button/index.js";
import BuyListSelect from "./components/buy-list-select/index.js";
import AddToBuyListIcon from "./components/add-to-buy-list-icon/index.js";
import {generateAmazonImageUrl} from "../../utils/generate-amazon-image-url.js";
const AddToBuyListButton = ({product}) => {
  const [errors, setErorrs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [buyLists, setBuyLists] = useState([]);
  const [form, updateForm] = useState({
    asin: product.asin,
    qty: 1,
    expirationDate: "",
    taxCode: "A_GEN_TAX",
    condition: "New",
    costPrice: 0,
    buyListId: "",
    notes: ""
  });
  const {qty, expirationDate, taxCode, condition, costPrice, buyListId} = form;
  const history = useHistory();
  const qtyInputRef = useRef(null);
  const addToBuyList = async (formData) => {
    const {buyListId: buyListId2, ...rest} = formData;
    await axios.post(`/api/v1/buy-list/${buyListId2}`, rest);
    history.push(`/buy-lists/${buyListId2}`);
  };
  useEffect(() => {
    getBuyLists();
    getRecentBuyList();
    if (!costPrice) {
      setErorrs((old) => old.includes("Cost should be greater than 0.") ? [...old] : [...old, "Cost should be greater than 0."]);
    }
    if (!buyListId) {
      setErorrs((old) => old.includes("Select a buy list.") ? [...old] : [...old, "Select a buy list."]);
    }
  }, []);
  useEffect(() => {
    if (!costPrice) {
      setErorrs((old) => old.includes("Cost should be greater than 0.") ? [...old] : [...old, "Cost should be greater than 0."]);
    }
    if (!buyListId) {
      setErorrs((old) => old.includes("Select a buy list.") ? [...old] : [...old, "Select a buy list."]);
    } else {
      setErorrs((old) => old.filter((err) => err != "Select a buy list."));
    }
  }, [form]);
  const handleAddToBuyList = (event) => {
    event.preventDefault();
    if (buyListId && costPrice) {
      addToBuyList({...form});
    }
  };
  const getBuyLists = async () => {
    const response = await axios.get("/api/v1/buy-list");
    setBuyLists(response.data);
  };
  const getRecentBuyList = async () => {
    const response = await axios.get("api/v1/buy-list/user/recent");
    updateForm((old) => {
      return {...old, buyListId: response.data.recentBuyList};
    });
  };
  const onInputChange = (event) => {
    let {name, value} = event.target;
    if (name === "qty")
      value = Number(value);
    updateForm((old) => {
      return {...old, [name]: value};
    });
  };
  const incrementQty = () => {
    updateForm((old) => {
      return {...old, qty: Number(qty) + 1};
    });
  };
  const decrementQty = () => {
    const oneDecreasedQty = Number(qty) - 1;
    const newQty = oneDecreasedQty < 0 ? "" : oneDecreasedQty;
    updateForm((old) => {
      return {...old, qty: newQty};
    });
  };
  const onNotesChange = (e) => {
    updateForm((old) => {
      return {...old, notes: e.target.value};
    });
  };
  const handleFocus = (event) => {
    event.target.scrollIntoView({behavior: "smooth", block: "nearest"});
    event.target.select();
  };
  const handleCostValueChange = (newCost) => {
    updateForm((old) => {
      return {...old, costPrice: newCost ? newCost : ""};
    });
    if (newCost == 0 || newCost == void 0) {
      setErorrs((old) => old.includes("Cost should be greater than 0.") ? [...old] : [...old, "Cost should be greater than 0."]);
    } else {
      setErorrs((old) => old.filter((err) => err != "Cost should be greater than 0."));
    }
  };
  const handleCostInputChange = (event) => {
    if (event.target.value === ".") {
      setTimeout(() => event.target.setSelectionRange(1, 1));
    }
  };
  const handleTaxCodeChange = (newTaxCode) => updateForm((old) => {
    return {...old, taxCode: newTaxCode};
  });
  const handleBuyListIdChange = (newBuyListId) => {
    updateForm((old) => {
      return {...old, buyListId: newBuyListId};
    });
    if (newBuyListId) {
      setErorrs((old) => old.filter((err) => err != "Select a buy list."));
    }
  };
  const handleQtyBackspace = (event) => {
    if (event.key === "Backspace" && qtyInputRef.current.value.length === 1) {
      updateForm((old) => {
        return {...old, qty: ""};
      });
    }
  };
  useEffect(() => {
    updateForm((old) => {
      return {...old, asin: product.asin};
    });
  }, [product]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    onClick: () => setIsOpen(true)
  }, /* @__PURE__ */ React.createElement(AddToBuyListIcon, null)), /* @__PURE__ */ React.createElement(Transition.Root, {
    show: isOpen,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    static: true,
    className: "fixed inset-0 overflow-hidden",
    open: isOpen,
    onClose: setIsOpen
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 overflow-hidden"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-in-out duration-500",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in-out duration-500",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
    className: "absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-y-0 left-0 max-w-full flex"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "transform transition ease-in-out duration-500 sm:duration-700",
    enterFrom: "-translate-x-full",
    enterTo: "translate-x-0",
    leave: "transform transition ease-in-out duration-500 sm:duration-700",
    leaveFrom: "translate-x-full",
    leaveTo: "-translate-x-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-screen max-w-md"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "h-full flex flex-col py-6 bg-white shadow-xl overflow-auto"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-start justify-between"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    className: "text-xl font-bold text-gray-900"
  }, "Add Product to Buy List"))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 relative flex-1 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 px-4 sm:px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col h-full border-t-2 border-gray-200",
    "aria-hidden": "true"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex mt-4"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "w-16 mr-2",
    src: generateAmazonImageUrl(product.image, 96)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm line-clamp-2"
  }, product.title), /* @__PURE__ */ React.createElement("p", {
    className: "text-xs text-gray-500 mt-1"
  }, "ASIN: ", product.asin))), /* @__PURE__ */ React.createElement("form", {
    className: "flex flex-col mt-4 flex-grow",
    onSubmit: handleAddToBuyList
  }, /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "QTY", /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "qty",
    type: "number",
    className: "order-2 border-none w-11 text-center mx-1 px-0 text-gray-500",
    onInput: onInputChange,
    onKeyDown: handleQtyBackspace,
    onFocus: handleFocus,
    value: qty,
    min: 1,
    ref: qtyInputRef,
    required: true
  }), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "order-1 text-white bg-gray-600 rounded-full p-1",
    onClick: decrementQty
  }, /* @__PURE__ */ React.createElement(SubtractFillIcon, {
    size: 18
  })), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "order-3 text-white bg-gray-600 rounded-full p-1",
    onClick: incrementQty
  }, /* @__PURE__ */ React.createElement(AddFillIcon, {
    size: 18
  })))), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Expiration Date", /* @__PURE__ */ React.createElement("input", {
    name: "expirationDate",
    type: "date",
    className: "flex flex-row-reverse appearance-none pr-0 border-none text-right text-gray-500",
    onInput: onInputChange,
    value: expirationDate
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Tax Code", /* @__PURE__ */ React.createElement(TaxCodeInput, {
    selectedTaxCode: taxCode,
    setTaxCode: handleTaxCodeChange
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, /* @__PURE__ */ React.createElement("span", null, "Cost ", /* @__PURE__ */ React.createElement("span", {
    className: "text-primary"
  }, " *")), /* @__PURE__ */ React.createElement(CurrencyInput, {
    name: "costPrice",
    className: "border-none text-right px-0 text-gray-500",
    prefix: "$",
    value: costPrice,
    onValueChange: handleCostValueChange,
    placeholder: "",
    required: true,
    onFocus: handleFocus,
    onChange: handleCostInputChange
  })), /* @__PURE__ */ React.createElement("label", {
    className: "flex items-center justify-between border-gray-300 text-gray-600 border-b h-12 text-sm"
  }, "Condition", /* @__PURE__ */ React.createElement("select", {
    name: "condition",
    className: "border-none bg-right pr-7 -mr-1",
    value: condition,
    onChange: onInputChange
  }, /* @__PURE__ */ React.createElement("option", {
    value: "New"
  }, "New"), /* @__PURE__ */ React.createElement("option", {
    value: "Old"
  }, "Old"))), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "notes",
    className: "text-sm text-gray-600 mt-4 mb-1"
  }, "Notes"), /* @__PURE__ */ React.createElement("textarea", {
    name: "notes",
    id: "notes",
    rows: "4",
    className: "resize-none rounded border border-gray-300 text-sm",
    onChange: onNotesChange
  }), /* @__PURE__ */ React.createElement("label", {
    className: "inline-flex flex-col font-bold my-4"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "mb-1"
  }, "Select Buy List ", /* @__PURE__ */ React.createElement("span", {
    className: "text-primary"
  }, " *")), /* @__PURE__ */ React.createElement(BuyListSelect, {
    setBuyListId: handleBuyListIdChange,
    selectedBuyListId: buyListId,
    buyLists,
    getBuyLists
  })), /* @__PURE__ */ React.createElement(CreateBuyListButton, {
    setBuyLists
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mt-8"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "mt-auto text-primary-100 text-xs mb-2 italic"
  }, errors && errors.length > 0 && errors.map((err, index) => /* @__PURE__ */ React.createElement("p", {
    key: index
  }, err))), /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-4 w-full justify-between mb-4"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "text-gray-800 bg-gray-300 flex-1 p-2 rounded",
    onClick: () => setIsOpen(false)
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    disabled: errors && errors.length > 0,
    className: "flex-1 p-2 rounded text-white bg-primary disabled:opacity-50 disabled:cursor-not-allowed"
  }, "Add to Buy List"))))))))))))));
};
export default AddToBuyListButton;
