import React, {useContext} from "../../../_snowpack/pkg/react.js";
import {useParams, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import useInfiniteScroll from "../../../_snowpack/pkg/react-infinite-scroll-hook.js";
import useSearchResult from "./useSearchResult.js";
import SearchResultInput from "../search-result-input/index.js";
import ScanResultsProducts from "../scan-results-product/index.js";
import {SearchContext} from "../../contexts/search-context.js";
const ScanResults = () => {
  const {barcode} = useParams();
  const decodeChar = [
    {char: "#", code: "%23"},
    {char: "$", code: "%24"},
    {char: "%", code: "%25"},
    {char: "!", code: "%21"},
    {char: "&", code: "%26"},
    {char: "'", code: "%27"},
    {char: "(", code: "%28"},
    {char: ")", code: "%29"},
    {char: "*", code: "%2A"},
    {char: "+", code: "%2B"},
    {char: ",", code: "%2C"},
    {char: "/", code: "%2F"},
    {char: ":", code: "%3A"},
    {char: ";", code: "%3B"},
    {char: "=", code: "%3D"},
    {char: "?", code: "%3F"},
    {char: "@", code: "%40"},
    {char: "[", code: "%5B"},
    {char: "]", code: "%5D"}
  ];
  const decodeKeyword = (keyword) => {
    let keywordCopy = keyword;
    if (keywordCopy.includes("%25")) {
      keywordCopy = keywordCopy.replaceAll("%25", "%");
    }
    decodeChar.forEach((d) => {
      if (keywordCopy.includes(d.code)) {
        keywordCopy = keywordCopy.replaceAll(d.code, d.char);
      }
    });
    let encodeKeyword = encodeURIComponent(keywordCopy);
    return decodeURIComponent(encodeKeyword);
  };
  const [searchText, setSearchText] = React.useState("");
  const {searchContext, setSearchContext, setPageIndex, setResults, isResultMounted} = useContext(SearchContext);
  const {loading, results, hasNextPage, error, loadMore} = useSearchResult(decodeKeyword(barcode));
  const location = useLocation();
  React.useEffect(() => {
    if (searchContext !== barcode) {
      console.log(`Before: searchContext:${searchContext}, barcode: ${barcode}`);
      setSearchContext(barcode);
      setPageIndex(0);
      setResults([]);
    }
    if (results?.length === 0) {
      loadMore();
    }
    console.log(`After: searchContext:${searchContext}, barcode: ${barcode}`);
    isResultMounted.current = true;
    return () => {
      isResultMounted.current = false;
    };
  }, [searchContext]);
  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    delayInMs: 10,
    disabled: !!error,
    onLoadMore: loadMore
  });
  const displayProducts = searchText ? results.filter((product) => {
    const productTitle = product.title.toLowerCase();
    return productTitle.includes(searchText.toLowerCase());
  }) : results;
  let component = "";
  if (error) {
    component = /* @__PURE__ */ React.createElement("div", {
      className: "flex justify-center"
    }, /* @__PURE__ */ React.createElement("span", {
      className: "my-10"
    }, "Something went wrong while searching."));
  } else if (loading) {
    if (results.length > 0) {
      component = /* @__PURE__ */ React.createElement("div", {
        className: "flex justify-center"
      }, /* @__PURE__ */ React.createElement("span", {
        className: "my-10"
      }, "Loading more result "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.1s"}
      }, ". "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.2s"}
      }, ". "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.3s"}
      }, ". "));
    } else {
      component = /* @__PURE__ */ React.createElement("div", {
        className: "flex justify-center"
      }, /* @__PURE__ */ React.createElement("span", {
        className: "my-10"
      }, "Loading "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.1s"}
      }, ". "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.2s"}
      }, ". "), /* @__PURE__ */ React.createElement("span", {
        className: "my-10 animate-bounce",
        style: {animationDelay: "0.3s"}
      }, ". "));
    }
  } else if (hasNextPage) {
    component = /* @__PURE__ */ React.createElement("div", {
      ref: infiniteRef,
      className: "flex justify-center"
    }, /* @__PURE__ */ React.createElement("span", {
      className: "my-10"
    }, " "));
  } else if (results.length > 0) {
    component = /* @__PURE__ */ React.createElement("div", {
      className: "flex justify-center"
    }, /* @__PURE__ */ React.createElement("span", {
      className: "my-10"
    }, "No more items"));
  } else {
    component = /* @__PURE__ */ React.createElement("div", {
      className: "flex justify-center"
    }, /* @__PURE__ */ React.createElement("span", {
      className: "my-10"
    }, "No Results Found"));
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "w-full h-full bg-white"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "italic p-4 ml-1 text-gray-800"
  }, 'Search results for "', /* @__PURE__ */ React.createElement("span", {
    className: "font-medium"
  }, decodeKeyword(barcode)), '"'), /* @__PURE__ */ React.createElement("div", {
    className: "px-4"
  }, /* @__PURE__ */ React.createElement(SearchResultInput, {
    value: searchText,
    onChange: (e) => setSearchText(e.target.value),
    placeholder: "Filter results"
  })), displayProducts.map((product) => /* @__PURE__ */ React.createElement(ScanResultsProducts, {
    key: product.asin,
    product
  })), component);
};
export default ScanResults;
