import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
import ReplenScoutLogo from "../replen-scout-logo/index.js";
const Register = ({handleLoginSuccess, openErrorNotif}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isSending, setIsSending] = useState(false);
  const sendRegistrationForm = async () => {
    try {
      const response = await axios.post("/api/v1/register", {name, email, password});
      const {token, user} = response.data;
      handleLoginSuccess(token, user);
    } catch (error) {
      let errorMessage;
      if (error?.response === void 0) {
        errorMessage = "No response, probably a network problem or backend is down.";
      } else {
        switch (error?.response?.status) {
          case 409:
            errorMessage = "Email already in use, please use another email.";
            break;
          case 500:
            errorMessage = "Server error, something went wrong on our end.";
            break;
          case 502:
            errorMessage = "Server error, backend is down right now.";
            break;
          default:
            errorMessage = "Something went wrong during registration.";
            console.error("Unhandled response:", error.response);
        }
      }
      openErrorNotif(errorMessage);
      setIsSending(false);
    }
  };
  const onRegister = (event) => {
    event.preventDefault();
    if (password !== confirmPass) {
      return openErrorNotif("Input and confirmation passwords do not match.");
    }
    setIsSending(true);
    sendRegistrationForm();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "sm:mx-auto sm:w-full sm:max-w-md"
  }, /* @__PURE__ */ React.createElement(ReplenScoutLogo, {
    className: "mx-auto h-8 sm:h-10 w-auto"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 sm:mx-auto sm:w-full sm:max-w-md"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "bg-white py-6 px-4 shadow sm:rounded-lg sm:px-10 mx-4"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "text-center text-3xl font-extrabold text-gray-900"
  }, "Create an Account"), /* @__PURE__ */ React.createElement("p", {
    className: "mt-2 text-center text-sm text-gray-600"
  }, "Sign up now to try us for free"), /* @__PURE__ */ React.createElement("form", {
    className: "space-y-2 sm:space-y-4 mt-4",
    onSubmit: onRegister
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "name",
    className: "block text-sm font-medium text-gray-700"
  }, "Full Name"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "name",
    type: "text",
    value: name,
    autoComplete: "name",
    onInput: (e) => setName(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email",
    className: "block text-sm font-medium text-gray-700"
  }, "Email Address"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "email",
    type: "email",
    value: email,
    autoComplete: "email",
    onInput: (e) => setEmail(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "password",
    className: "block text-sm font-medium text-gray-700"
  }, "Enter Password"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "password",
    type: "password",
    value: password,
    autoComplete: "current-password",
    onInput: (e) => setPassword(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "password",
    className: "block text-sm font-medium text-gray-700"
  }, "Confirm Password"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "confirmPass",
    type: "password",
    value: confirmPass,
    onInput: (e) => setConfirmPass(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
    disabled: isSending,
    type: "submit",
    className: "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 disabled:opacity-50 mt-4"
  }, isSending ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spinner, {
    className: "-ml-1 mr-3 h-5 w-auto text-white"
  }), " Signing up...") : "Sign Up")))), /* @__PURE__ */ React.createElement("p", {
    className: "mt-4 text-center text-sm text-gray-600"
  }, "Already a member? ", " ", /* @__PURE__ */ React.createElement(Link, {
    to: "/auth/login/scout",
    className: "font-medium text-primary hover:text-primary-400 rounded-sm focus:ring-2 focus:ring-primary-400 focus:ring-offset-1 focus:outline-none underline"
  }, "Log in"))));
};
export default Register;
