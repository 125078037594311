import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
import ReplenDashboardLogo from "../replen-dashboard-logo/index.js";
function ScoutLogin({handleLoginSuccess, openErrorNotif}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const sendLoginRequest = async () => {
    try {
      const response = await axios.post("/api/v1/login/dashboard", {email, password});
      const {token, user} = response.data;
      handleLoginSuccess(token, user);
    } catch (error) {
      let errorMessage;
      if (error?.response === void 0) {
        errorMessage = "No response, probably a network problem or backend is down.";
      } else {
        switch (error?.response?.status) {
          case 401:
            errorMessage = "Invalid credentials, please check your email or password.";
            break;
          case 403:
            errorMessage = "You do not have permission to use this app.";
            break;
          case 500:
            errorMessage = "Server error, something went wrong on our end.";
            break;
          case 502:
            errorMessage = "Server error, backend is down right now.";
            break;
          default:
            errorMessage = "Something went wrong during login.";
            console.error("Unhandled response:", error.response);
        }
      }
      openErrorNotif(errorMessage);
      setIsSending(false);
    }
  };
  const onLogin = (event) => {
    event.preventDefault();
    setIsSending(true);
    sendLoginRequest();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "sm:mx-auto sm:w-full sm:max-w-md"
  }, /* @__PURE__ */ React.createElement(ReplenDashboardLogo, {
    className: "mx-auto h-12 sm:h-16 w-auto"
  }), /* @__PURE__ */ React.createElement("h2", {
    className: "mt-4 sm:mt-6 text-center text-3xl font-extrabold text-gray-900"
  }, "Sign In"), /* @__PURE__ */ React.createElement("p", {
    className: "mt-2 text-center text-sm text-gray-600"
  }, "Don't have an account yet? ", " ", /* @__PURE__ */ React.createElement(Link, {
    to: "/auth/register",
    className: "font-medium text-primary hover:text-primary-400 rounded-sm focus:ring-2 focus:ring-primary-400 focus:ring-offset-1 focus:outline-none underline"
  }, "Create one"))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-4 sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mx-4"
  }, /* @__PURE__ */ React.createElement("form", {
    className: "space-y-4 sm:space-y-6",
    onSubmit: onLogin
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email",
    className: "block text-sm font-medium text-gray-700"
  }, "Email address"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "email",
    type: "email",
    value: email,
    placeholder: "Email Address",
    autoComplete: "email",
    onInput: (e) => setEmail(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "password",
    className: "block text-sm font-medium text-gray-700"
  }, "Password"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-1"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "password",
    type: "password",
    value: password,
    placeholder: "Password",
    autoComplete: "current-password",
    onInput: (e) => setPassword(e.target.value),
    required: true,
    className: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-sm"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center justify-between"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "rememberMe",
    type: "checkbox",
    value: rememberMe,
    onChange: () => setRememberMe(!rememberMe),
    className: "h-4 w-4 text-primary-500 focus:ring-primary-400 border-gray-300 rounded"
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "rememberMe",
    className: "ml-2 block text-sm text-gray-900"
  }, "Remember me")), /* @__PURE__ */ React.createElement("div", {
    className: "text-sm"
  }, /* @__PURE__ */ React.createElement("a", {
    href: "# ",
    className: "font-medium text-gray-500 hover:text-gray-400 rounded-sm focus:ring-2 focus:ring-primary-400 focus:ring-offset-4 focus:outline-none focus:text-gray-700 focus:underline"
  }, "Forgot your password?"))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
    disabled: isSending,
    type: "submit",
    className: "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 disabled:opacity-50 disabled:cursor-not-allowed"
  }, isSending ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spinner, {
    className: "-ml-1 mr-3 h-5 w-auto text-white"
  }), " Logging in...") : "Login"))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "relative"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 flex items-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-full border-t border-gray-300"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "relative flex justify-center text-sm"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "px-2 bg-white text-gray-500"
  }, "Or continue with"))), /* @__PURE__ */ React.createElement(Link, {
    to: "/auth/login/scout",
    type: "button",
    className: "inline-flex items-center px-4 py-2 mt-6 w-full justify-center border-2 border-primary shadow-sm text-sm font-bold rounded-md text-primary bg-white hover:bg-gray-00 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 appearance-none"
  }, "Sign in with Replen Scout")))));
}
export default ScoutLogin;
