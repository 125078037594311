import React, {Fragment, useRef, useState} from "../../../_snowpack/pkg/react.js";
import {Dialog, Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import {PlusIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
export default function CreateBuyListModal({onSuccess, setBuyLists}) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const nameInputRef = useRef(null);
  const createNewBuyList = async (name2) => {
    setIsAdding(true);
    if (setBuyLists || setBuyLists != void 0) {
      const response = await axios.post("/api/v1/buy-list", {name: name2});
      setBuyLists((old) => [...old, response.data]);
    } else {
      await axios.post("/api/v1/buy-list", {name: name2});
    }
    setName("");
    setIsOpen(false);
    setIsAdding(false);
    onSuccess();
  };
  const handleCreate = () => {
    createNewBuyList(name);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "flex items-center text-primary self-end text-sm text-center border border-red-200 px-2 py-1",
    onClick: () => setIsOpen(true)
  }, /* @__PURE__ */ React.createElement(PlusIcon, {
    className: "h-4 mr-1"
  }), " Create New"), /* @__PURE__ */ React.createElement(Transition.Root, {
    show: isOpen,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    static: true,
    className: "fixed z-10 inset-0 overflow-y-auto",
    initialFocus: nameInputRef,
    open: isOpen,
    onClose: setIsOpen
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
    className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("span", {
    className: "hidden sm:inline-block sm:align-middle sm:h-screen",
    "aria-hidden": "true"
  }, "​"), /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    enterTo: "opacity-100 translate-y-0 sm:scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
    leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "mt-3 text-center sm:mt-5"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    as: "h3",
    className: "text-lg leading-6 font-medium text-gray-900"
  }, "Create New Buy List"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-2"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-gray-500 mb-2"
  }, "Enter the buy list name"), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    className: "border-gray-400 border-2 rounded focus:border-primary-300 focus:ring-primary-300",
    value: name,
    onInput: (e) => setName(e.target.value),
    ref: nameInputRef
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 grid grid-cols-2 gap-3 grid-flow-row-dense"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 sm:col-start-2 sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed",
    onClick: handleCreate,
    disabled: isAdding
  }, isAdding ? /* @__PURE__ */ React.createElement(Spinner, {
    className: "h-6 text-white"
  }) : "Create"), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 sm:mt-0 sm:col-start-1 sm:text-sm",
    onClick: () => setIsOpen(false)
  }, "Cancel"))))))));
}
