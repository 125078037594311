import React, {Fragment} from "../../../_snowpack/pkg/react.js";
import {Transition} from "../../../_snowpack/pkg/@headlessui/react.js";
import {CheckCircleIcon} from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import {XCircleIcon, InformationCircleIcon, ExclamationIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import {XIcon} from "../../../_snowpack/pkg/@heroicons/react/solid.js";
const Notif = ({isOpen = false, type = "success", title, message, handleClose}) => {
  const notifTypes = {
    success: /* @__PURE__ */ React.createElement(CheckCircleIcon, {
      className: "h-6 w-6 text-green-400",
      "aria-hidden": "true"
    }),
    danger: /* @__PURE__ */ React.createElement(XCircleIcon, {
      className: "h-6 w-6 text-red-400",
      "aria-hidden": "true"
    }),
    info: /* @__PURE__ */ React.createElement(InformationCircleIcon, {
      className: "h-6 w-6 text-blue-400",
      "aria-hidden": "true"
    }),
    warning: /* @__PURE__ */ React.createElement(ExclamationIcon, {
      className: "h-6 w-6 text-yellow-400",
      "aria-hidden": "true"
    })
  };
  return /* @__PURE__ */ React.createElement("div", {
    "aria-live": "assertive",
    className: "fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-full flex flex-col items-center space-y-4 sm:items-end"
  }, /* @__PURE__ */ React.createElement(Transition, {
    show: isOpen,
    as: Fragment,
    enter: "transform ease-out duration-200 transition",
    enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
    enterTo: "translate-y-0 opacity-100 sm:translate-x-0",
    leave: "transition ease-in duration-150",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "p-4"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-start"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex-shrink-0"
  }, notifTypes[type]), /* @__PURE__ */ React.createElement("div", {
    className: "ml-3 w-0 flex-1 pt-0.5"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm font-medium text-gray-900"
  }, title), /* @__PURE__ */ React.createElement("p", {
    className: "mt-1 text-sm text-gray-500"
  }, message)), /* @__PURE__ */ React.createElement("div", {
    className: "ml-4 flex-shrink-0 flex"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
    onClick: handleClose
  }, /* @__PURE__ */ React.createElement("span", {
    className: "sr-only"
  }, "Close"), /* @__PURE__ */ React.createElement(XIcon, {
    className: "h-5 w-5",
    "aria-hidden": "true"
  })))))))));
};
export default Notif;
